import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import {
  MUTATION_CANCEL_BONUS,
  MUTATION_CANCEL_SPORTS_BONUS,
} from "common/src/graphql/mutations";
import {
  BonusWrapper,
  BonusContainerLeft,
  BonusImg,
  BonusContainerRight,
  BonusDescriptionSection,
} from "../styledComponents/StyledActiveBonus";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import React from "react";
import { TransactionNotFound } from "../styledComponents/AccountPage";

const ActiveBonus = ({
  activeBonuses,
  pages,
  server,
  bonusImagePath,
  activeBonusTab,
}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();

  async function cancelBonus(uid) {
    //console.log(uid);
    const { loading, errors } = await apolloClient.mutate({
      mutation: activeBonusTab
        ? MUTATION_CANCEL_SPORTS_BONUS
        : MUTATION_CANCEL_BONUS,
      variables: {
        uniqueID: uid,
      },
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else window.location = pages["bonuses"];

    return true;
  }

  return (
    <>
      {/*active-bonuses  */}
      {activeBonuses && Object.keys(activeBonuses).length !== 0 ? (
        activeBonuses.map((bonus, index) => (
          <BonusWrapper key={bonus.uniqueID}>
            <BonusContainerLeft>
              <BonusImg>
                <img
                  src={
                    bonus.bonusImage === ""
                      ? server.cdn + bonusImagePath + "bonus-page-bonus.png"
                      : server.cdn + bonusImagePath + bonus.bonusImage
                  }
                  alt="marketing_bns_manual.png"
                />
                <BonusDescriptionSection>
                  <div>
                    {" "}
                    {bonus.bonusDescription === ""
                      ? t("label_empty_bonus_description")
                      : t(bonus.bonusDescription)}
                  </div>
                  {activeBonusTab ? null : (
                    <div>
                      <span className="progress-text">
                        {t("label_wagering_progress")}
                      </span>{" "}
                      <span className="ui-label float-right">
                        <b>
                          {bonus.wagered === 0
                            ? "0"
                            : parseInt(
                                (bonus.wagered * 100) / bonus.wageringAmount
                              )}
                          %
                        </b>
                      </span>
                      <div className="my-3 progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width:
                              bonus.wagered === 0
                                ? "0%"
                                : parseInt(
                                    (bonus.wagered * 100) / bonus.wageringAmount
                                  ) + "%",
                          }}
                          aria-valuenow={
                            bonus.wagered === 0
                              ? "0"
                              : parseInt(
                                  (bonus.wagered * 100) / bonus.wageringAmount
                                )
                          }
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  )}
                </BonusDescriptionSection>
              </BonusImg>
            </BonusContainerLeft>
            <BonusContainerRight>
              <StyledButton btn onClick={() => cancelBonus(bonus.uniqueID)}>
                {t("label_cancel_bonus")}
              </StyledButton>
            </BonusContainerRight>
          </BonusWrapper>
        ))
      ) : (
        <TransactionNotFound
          dangerouslySetInnerHTML={{ __html: t("bonuses_not_found") }}
        ></TransactionNotFound>
      )}
    </>
  );
};

export default ActiveBonus;
