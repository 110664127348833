import React from "react";
import {
  StyledLink,
  StyledNavLink,
} from "../styledComponents/CommonStyledComponents";
import { useTranslation } from "react-i18next";
import { StyledQuickGameCategories } from "../styledComponents/StyledGamesCategories";
import { useLocation } from "react-router-dom";

const QuickGameCategories = ({
  currentLanguage,
  games,
  allLinks,
  player,
  hideOnScroll,
  skin,
}) => {
  const { t } = useTranslation();

  let location = useLocation();

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    window.location.href = url;
  };

  return allLinks ? (
    <StyledQuickGameCategories player={player} hideOnScroll={!hideOnScroll}>
      <StyledNavLink
        as="div"
        quickSB={skin === "qbc"}
        activeClassName="active"
        className={
          location.pathname === `/${currentLanguage}/sports-book/sports` &&
          "active"
        }
        href={"/" + currentLanguage + "/sports-book/sports"}
        onClick={(e) =>
          handleLinkClick(e, "/" + currentLanguage + "/sports-book/sports")
        }
      >
        {t("label_sportsbook")}
      </StyledNavLink>
      <StyledNavLink
        activeClassName="active"
        to={"/" + currentLanguage + (games ? "/games" : "/index")}
      >
        {t("label_casino")}
      </StyledNavLink>
      <StyledNavLink
        activeClassName="active"
        to={"/" + currentLanguage + "/livecasino"}
      >
        {t("label_live_casino")}
      </StyledNavLink>
    </StyledQuickGameCategories>
  ) : (
    <StyledQuickGameCategories player={player} hideOnScroll={!hideOnScroll}>
      {location.pathname !== `/${currentLanguage}/livecasino` && (
        <StyledLink to={"/" + currentLanguage + "/livecasino"}>
          {t("label_live_casino")}
        </StyledLink>
      )}
      {(location.pathname === `/${currentLanguage}/livecasino` ||
        location.pathname === `/${currentLanguage}/sports-book/sports`) && (
        <StyledLink to={"/" + currentLanguage + (games ? "/games" : "/index")}>
          {t("label_casino")}
        </StyledLink>
      )}
      {location.pathname !== `/${currentLanguage}/sports-book/sports` && (
        <StyledLink to={"/" + currentLanguage + "/sports-book/sports"}>
          {t("label_sportsbook")}
        </StyledLink>
      )}
    </StyledQuickGameCategories>
  );
};

export default QuickGameCategories;
