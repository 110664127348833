import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as NewsIconGift } from "../assets/icon-component/gift-outline.svg";
import { ReactComponent as DepositIcon } from "../assets/icon-component/cash-plus.svg";
import { ReactComponent as SwipeLine } from "../assets/icon-component/swipe-line.svg";
import { ReactComponent as SlotMachine } from "../assets/icon-component/slot-machine.svg";
import { ReactComponent as Dice } from "../assets/icon-component/dice.svg";

export const MobileNavWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 55px;
  z-index: 100;
  background-color: var(--mobileNavWrapperBg);
  background: ${({ theme }) => theme.skin === "abc" && "var(--navBg)"};
`;

export const NavIcons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "qbc"
      ? "var(--mobileNavActiveBg)"
      : theme.skin === "fun" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac"
        ? "var(--mobileNavBg)"
        : "#1f1f1fc9"};
  background: ${({ theme }) => theme.skin === "abc" && "var(--navBg)"};

  .btn {
    /* background-image: ${({ theme }) => theme.skin === "rac" && "none"}; */
    min-width: ${({ theme }) => theme.skin === "rac" && "0"};
    font-weight: 400;
  }
`;

export const StyledMobileNavLink = styled(Link).attrs({
  className: "btn",
})`
  background-color: transparent;
  z-index: 100;

  &:hover {
    background-color: transparent;
  }
  &.active {
    svg {
      fill: #fff;
    }
  }
  background-image: ${({ theme }) => theme.skin === "rac" && "none"};
`;

export const StyledNewsIconGift = styled.div`
  width: 20%;
  font-variation-settings: "FILL" 1;
  &:after {
    font-family: "Material Icons";
    content: "newspaper";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, theme }) =>
      active
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac" ||
          theme.skin === "qbc"
          ? "var(--navIconColor)"
          : "#fff"
        : "var(--icon)"};
  }
`;

export const StyledSwipeLine = styled(SwipeLine)`
  fill: #fff;
`;

/* export const StyledDepositIcon = styled(DepositIcon)`
  background-color: ${({ active, theme }) => {
    if (
      theme.skin === "abc" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "rac"
    ) {
      return active ? "var(--profileBubble)" : "var(--icon)";
    } else {
      return active ? "#fff" : theme.skin === "fun" ? "#fa2c70" : "var(--icon)";
    }
  }};

  fill: ${({ active, theme }) => {
    if (theme.skin === "abc" || theme.skin === "nbc") {
      return active ? "var(--brandcolor)" : "var(--navProfileIconColor)";
    } else if (
      theme.skin === "yet" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
    ) {
      return active ? "var(--navIconColor)" : "var(--navProfileIconColor)";
    } else {
      return active
        ? theme.skin === "fun"
          ? "#1e8ea6"
          : "var(--icon)"
        : "#fff";
    }
  }};
  border-radius: 50%;
  padding: 3px;
`; */

export const StyledDepositIcon = styled.div`
  border-radius: 50%;
  margin: auto;
  background-color: ${({ active }) =>
    active ? "var(--navDepositIconActiveBg)" : "var(--navDepositIconBg)"};
  width: 45px;
  font-variation-settings: "FILL" 1;
  &:after {
    font-family: "Material Icons";
    content: "add_card";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active }) =>
      active
        ? "var(--navDepositIconInnerActiveColor)"
        : "var(--navDepositIconInnerColor)"};
  }
`;

export const StyledSlotMachine = styled(SlotMachine)`
  margin: auto;
  fill: ${({ active, theme }) =>
    active
      ? theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak" ||
        theme.skin === "rac" ||
        theme.skin === "qbc"
        ? "var(--navIconColor)"
        : "#fff"
      : "var(--icon)"};
  padding-top: ${({ theme }) => (theme.skin === "abc" ? "" : "5px")};
  width: 35px;
`;

export const DiceWrapper = styled.div`
  width: 20%;
`;

export const StyledGamesWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  position: ${({ showGamesMenu }) => (showGamesMenu ? "relative" : "static")};
  bottom: 65px;
  background-color: ${({ showGamesMenu }) =>
    showGamesMenu ? "var(--gameWrapper)" : "transparent"};
  border-radius: 30px;
  margin: auto;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  box-shadow: ${({ showGamesMenu }) =>
    showGamesMenu && "4px 8px 8px hsl(0deg 0% 0% / 0.38)"};
  padding: 5px 0;
`;

export const SlotWrapper = styled.div`
  width: 20%;
`;

export const StyledDice = styled.div`
  transform: ${({ showGamesMenu }) => showGamesMenu && "rotate(30deg)"};
  font-variation-settings: "FILL" 1;
  /* margin: ${({ logedIn }) => logedIn && "auto"}; */
  width: 100%;
  &:after {
    font-family: "Material Icons";
    content: "casino";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, theme }) =>
      active
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac" ||
          theme.skin === "qbc"
          ? "var(--navIconColor)"
          : "#fff"
        : "var(--icon)"};
  }
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
`;
export const StyledSports = styled.div`
  font-variation-settings: "FILL" 1;
  margin: auto;
  &:after {
    font-family: "Material Icons";
    content: "sports_basketball";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, theme }) =>
      active
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac" ||
          theme.skin === "qbc"
          ? "var(--navIconColor)"
          : "#fff"
        : "var(--icon)"};
  }
`;

export const StyledlivecasinoNav = styled.div`
  /* transform: rotate(30deg); */
  font-variation-settings: "FILL" 1;
  margin: auto;
  &:after {
    font-family: "Material Icons";
    content: "playing_cards";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, theme }) =>
      active
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac" ||
          theme.skin === "qbc"
          ? "var(--navIconColor)"
          : "#fff"
        : "var(--icon)"};
  }
`;

export const WalletIcon = styled.div`
  width: 15%;
  font-variation-settings: "FILL" 1;
  &:after {
    font-family: "Material Icons";
    content: "account_balance_wallet";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, theme }) =>
      active
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "rac" ||
          theme.skin === "qbc"
          ? "var(--navIconColor)"
          : "#fff"
        : "var(--icon)"};
  }
`;

export const GamesIcon = styled(NavLink)`
  width: 20%;
  &.active {
    &:after {
      color: ${({ theme }) => (theme.skin === "nbc" ? "#70328e" : "#fff")};
    }
  }
  font-variation-settings: "FILL" 1;
  /* transform: rotate(25deg); */
  &:after {
    font-family: "Material Icons";
    content: "casino";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active }) => (active ? "#fff" : "var(--icon)")};
  }
`;

export const WalletModalWrapper = styled.div.attrs((props) => ({
  className: props.toogle ? "show" : "hide",
}))`
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.skin === "fun" ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.85)"};

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const MobileWalletDiv = styled.div`
  overflow: hidden;
  width: 100%;
  height: ${({ expand }) => (expand ? "175px" : "0px")};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* background-color: #ffffffc9; */
  background-color: var(--mobileNavBg);
  position: fixed;
  bottom: 55px;
  left: 0;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 4px 8px 8px hsl(0deg 0% 0% / 0.38);
`;

export const StyledFlexBetween = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: ${({ profile }) => profile && "1rem 0"};
  border-top: ${({ profile, theme }) =>
    profile &&
    (theme.skin === "abc" || theme.skin === "rac" || theme.skin === "yet"
      ? "2px solid var(--mobileNavBorder)"
      : "2px solid #2f2f2f")};
  @media screen and (orientation: landscape) {
    padding: ${({ profile }) => profile && "0.4rem 0"};
  }
`;

export const ProfileIcon = styled.div`
  border-radius: ${({ notLogedIn }) => (notLogedIn ? "50%" : "")};
  background-color: ${({ active, notLogedIn }) =>
    notLogedIn &&
    (active
      ? "var(--navProfileIconLogoutActiveColor)"
      : "var(--navProfileIconLogoutColor)")};
  /* notLogedIn ? (active ? "#fff" : "var(--icon)") : ""}; */
  width: ${({ notLogedIn }) => (notLogedIn ? "45px" : "15%")};
  font-variation-settings: "FILL" 1;
  &:after {
    font-family: "Material Icons";
    content: "person";
    vertical-align: bottom;
    font-size: 1.9rem;
    color: ${({ active, notLogedIn }) => {
      if (notLogedIn) {
        if (active) {
          return "var(--profileIconInnerLogoutColor)";
        } else {
          return "var(--profileIconInnerColor)";
        }
      } else {
        if (active) {
          return "var(--profileIconInnerColorActive)";
        } else {
          return "var(--icon)";
        }
      }
    }};
  }
`;

export const ProfileModalWrapper = styled.div.attrs((props) => ({
  className: props.toogle ? "show" : "hide",
}))`
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--profileModalWrapperBg);
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const MobileProfileDiv = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: ${({ expand }) => (expand ? "500px" : "0px")};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* background-color: #ffffffc9; */
  background-color: var(--mobileNavBg);
  position: fixed;
  bottom: 55px;
  left: 0;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 4px 8px 8px hsl(0deg 0% 0% / 0.38);

  .btn {
    padding: 0.5rem 0.5rem;
  }
`;

export const HeaderAndThemeWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  justify-content: var(--headerAndThemeWrapperAlign);
  svg {
    height: 40px;
  }
`;

export const MobileProfileHeader = styled.h1`
  color: var(--mobileProfilHeaderColor);
  text-transform: capitalize;
  text-align: var(--mobileProfilHeaderAlign);
  padding: 0rem;
  letter-spacing: 2px;
  margin: var(--mobileProfilHeaderMargin);
  margin-bottom: var(--mobileProfilHeaderMarginBottom);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
`;
