import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import {
  MUTATION_AWARD_MANUAL_BONUS_CODE,
  MUTATION_AWARD_MANUAL_SPORTS_BONUS_CODE,
} from "common/src/graphql/mutations";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledFelxTableRow,
  StyledFormGroup,
  StyledInput,
} from "../styledComponents/CommonStyledComponents";
import { StyledLimitWrapper } from "../styledComponents/StyledLimits";

const BonusCode = ({ activeBonusTab, skin }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [bonusCode, setBonusCode] = useState("");

  useEffect(() => {
    setBonusCode("");
  }, [activeBonusTab]);

  async function redeemCodeManual() {
    //check is uk and Experian redeemBonusCodeManual
    const { loading, errors, data } = await apolloClient.mutate({
      mutation:
        activeBonusTab === "sports"
          ? MUTATION_AWARD_MANUAL_SPORTS_BONUS_CODE
          : MUTATION_AWARD_MANUAL_BONUS_CODE,
      variables: {
        bonusCode: bonusCode,
      },
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      setBonusCode("");
      return false;
    } else {
      if (data) {
        toast.success(
          <span
            dangerouslySetInnerHTML={{ __html: t("label_bonus_success") }}
          ></span>,
          {
            position: "bottom-center",
            autoClose: 2000, // 1 second
            onClose: () => {
              window.location.reload();
            },
          }
        );
      }
      setBonusCode("");
    }

    return true;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && bonusCode.length > 0) {
      redeemCodeManual();
    }
  };

  /* return activeBonusTab ? (
    <StyledLimitWrapper selfexcludion bonuscode>
      <div>{t("label_no_redeem_option")}</div>
    </StyledLimitWrapper>
  ) :  */ return (
    <StyledLimitWrapper
      selfexcludion
      bonuscode
      onKeyDown={(event) =>
        event.key === "Enter" && bonusCode.length > 0
          ? redeemCodeManual()
          : null
      }
    >
      <StyledFormGroup margin={"m-0"} col={"col-md-8 text-left"}>
        <label
          dangerouslySetInnerHTML={{ __html: t("label_insert_code") }}
        ></label>
        <StyledInput
          bonus
          name="code"
          value={bonusCode}
          onChange={(e) => setBonusCode(e.target.value)}
        />
      </StyledFormGroup>

      <StyledButton
        selfexcludion
        pub={skin === "pub"}
        redemecode={skin !== "pub" || skin === undefined}
        disabled={bonusCode.length === 0}
        onClick={() => redeemCodeManual()}
        dangerouslySetInnerHTML={{
          __html: t("label_activate_bonus", "Activate"),
        }}
      ></StyledButton>
    </StyledLimitWrapper>
  );
};

export default BonusCode;
