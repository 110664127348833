import { useEffect, useState } from "react";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { QUERY_CHECK_EMAIL } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";
import { format, addYears } from "date-fns";
import { PaymentServer } from "common/src/helpers/connectors";
import { toast } from "react-toastify";

const useForm = (
  loginOnRegistration,
  recaptchaSiteKey,
  defaultCurrency,
  defaultCountry,
  validate,
  callback
) => {
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const apolloClient = useApolloClient();
  // Retrieve registrationData from localStorage
  const registrationData =
    JSON.parse(localStorage.getItem("registrationData")) || {};

  // Clear registrationData from localStorage on page refresh
  useEffect(() => {
    localStorage.removeItem("registrationData");
  }, []);

  async function checkRecaptcha(email) {
    if (email !== "") {
      if (typeof window.grecaptcha !== "undefined") {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(recaptchaSiteKey, { action: "registerEmail" })
            .then((token) => {
              // console.log(token);
              // token.length > 0 && setToken(true);
              let params = {
                remoteIP: serverSetup.geoIP.ipAddress,
                responseToken: token,
              };
              PaymentServer.post("/fhnd/v1/recaptcha/verify", params)
                .then((res) => {
                  // console.log("success: " + JSON.stringify(res.data.response));
                  checkEmail(email);
                })
                .catch((error) => {
                  console.log(error);
                  return true;
                  // console.log("error: " + JSON.stringify(error));
                  // console.log("finish catch");
                });
            });
        });
      } else {
        checkEmail(email);
      }
    }
  }

  // Check if email exist
  async function checkEmail(email) {
    try {
      // console.log("check email:", email);
      const { loading, errors } = await apolloClient.query({
        query: QUERY_CHECK_EMAIL,
        variables: {
          email: email,
        },
      });

      if (loading) return "Loading ...";
      if (errors) {
        return true; //errors[0].message;
      }
      return false;
    } catch (ex) {}
  }

  const defaultData = {
    email: registrationData.email ? registrationData.email : "",
    password: "",
    currency: defaultCurrency ? defaultCurrency : "",
    country: defaultCountry ? defaultCountry : "",
    firstName: registrationData.firstName || "",
    middleName: "",
    lastName: registrationData.lastName || "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
    phone: "",
    gender: "",
    title: "",
    dateOfBirth: format(new Date(addYears(new Date(), -18)), "yyyy-MM-dd"),
    affiliateTrackingCode: "",
    language: serverSetup.currentLanguage,
    abode: "",
    buildingNumber: "",
    buildingName: "",
    identID: "",
    identBankID: "",
    geoIP: "",
    birthPlace: "", //NL specific
    BSN: "", //NL specific
    inserts: "", //NL specific
    addictionPolicy: false, //NL specific
    receiveNews: false,
    receiveSMS: false,
    gdprAccepted: false,
    pep: false,
    legalCapacity: false, //NL specific
    amlPolicy: false, //NL specific
    receiveBonus: false, //NL specific
    receiveSportsBonus: false, //Sports specific default
    activate: loginOnRegistration,
    // loginCountDate: 'daily',
    // loginCountValue: '',
    timeLimitDate: "daily",
    timeLimitValue: "",
    depositDate: "daily",
    depositValue: "",
    maxBalansLimit: "",
    // kycDocumentType: "none",
    // kycIDNumber: "",
    // kycDocumentCity: "",
    // documentValidFrom: "",
    // documentValidTo: "",
    bankIBAN: "",
    bankAccountHolder: "",
  };

  const [formData, setForm] = useState(defaultData);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  //const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch additional data and prepare gui
  /* useEffect(() => {
    // Get geoip informations
    setForm({ ...formData, geoIP: "127.0.0.1 | localhost | macOS" });
  }, []); */

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    //setIsSubmitting(true);
    if (Object.keys(errors).length === 0) {
      callback();
    }
  };

  const handleChange = (event) => {
    event.persist();
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (name === "bankIBAN") {
      //trim iban
      setForm({ ...formData, [name]: value.replace(/ /g, "") });
    } else {
      /*  if (name === "buildingNumber") {
        setForm({ ...formData, [name]: parseInt(value) });
      } else */
      setForm({ ...formData, [name]: value });
    }
  };

  const handlePhoneChange = (value, country) => {
    if (country && value.slice(country.dialCode.length) === "") {
      errors.phone = "err_empty_phone";
    } else {
      delete errors.phone;
    }
    setForm({ ...formData, phone: value });
    setErrors(validate(formData, errors));
    setTouched({ ...touched, phone: true });
  };

  const handleDateChange = (date) => {
    //console.log(date);
    let value = date !== null ? format(new Date(date), "yyyy-MM-dd") : null;
    if (value === null) {
      errors.dateOfBirth = "err_empty_date_of_birth";
      setForm({ ...formData, dateOfBirth: "" });
    } else {
      /*    else if (!underAgeValidate(value)) {
      errors.dateOfBirth = "err_underage_date_of_birth";
    } */
      delete errors.dateOfBirth;
      setForm({ ...formData, dateOfBirth: value });
    }
    setTouched({ ...touched, dateOfBirth: true });
    setErrors(validate(formData, errors));
  };

  // const handleDateRangeChange = (dateFrom, dateTo) => {
  //   if (dateFrom) {
  //     let valueFrom =
  //       dateFrom !== null ? moment(dateFrom).format("YYYY-MM-DD") : null;
  //
  //     if (valueFrom === null) {
  //       errors.documentValidFrom = "err_empty_document_valid_from";
  //     } else {
  //       delete errors.documentValidFrom;
  //       setForm({ ...formData, documentValidFrom: valueFrom });
  //     }
  //
  //     setTouched({ ...touched, documentValidFrom: true });
  //   }
  //   if (dateTo) {
  //     let valueTo =
  //       dateTo !== null ? moment(dateTo).format("YYYY-MM-DD") : null;
  //     if (valueTo === null) {
  //       errors.documentValidTo = "err_empty_document_valid_to";
  //     } else {
  //       delete errors.documentValidTo;
  //       setForm({ ...formData, documentValidTo: valueTo });
  //     }
  //     setTouched({ ...touched, documentValidTo: true });
  //   }
  //   if (dateFrom && dateTo) {
  //     if (!dateTo.isAfter(dateFrom)) {
  //       errors.documentValidInvalidRange = "err_date_invalid_range";
  //     } else {
  //       delete errors.documentValidInvalidRange;
  //     }
  //   }
  //   setErrors(validate(formData, errors));
  // };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    // console.log("handle blur: " + name);
    if (name === "email" && value !== "") {
      //console.log();
      //   console.log("check email")
      checkRecaptcha(formData.email).then((data) => {
        console.log(data);
        data
          ? (errors.email = "err_email_already_exists")
          : delete errors.email;
        setForm({ ...formData, email: value });
      });
    }
    if (name === "timeLimitValue" && value !== "") {
      //console.log();
      //   console.log("check email")
      // console.log(formData.timeLimitDate)
      if (formData.timeLimitDate === "" || formData.timeLimitDate === "daily") {
        if (value > 24) {
          setForm({ ...formData, timeLimitValue: 24 });
        }
      } else if (formData.timeLimitDate === "weekly") {
        if (value > 168) {
          setForm({ ...formData, timeLimitValue: 168 });
        }
      } else if (formData.timeLimitDate === "monthly") {
        if (value > 720) {
          setForm({ ...formData, timeLimitValue: 720 });
        }
      }
    }
    //console.log(event.currentTarget.value + " " + event.target.value);
    setErrors(validate(formData, errors));
    setTouched({ ...touched, [name]: true });
  };

  const handleOnDateBlur = ({ date }) => {
    let newList = { ...errors };
    if (date._isValid) {
      delete newList.dateOfBirth;
    }
    //console.log(newList);
    setTouched({ ...touched, dateOfBirth: true });
    setErrors(newList);
  };

  const handleOnPhoneBlur = (e, country) => {
    const { name } = e.target;
    let inputValue = e.currentTarget.value.replace("+", "");
    //console.log(inputValue.slice(country.dialCode.length));
    if (
      e.currentTarget.value === "" ||
      (country && inputValue.slice(country.dialCode.length) === "")
    ) {
      errors.phone = "err_empty_phone";
    } else {
      delete errors.phone;
    }
    setErrors(validate(formData, errors));
    setTouched({ ...touched, [name]: true });
  };

  const setTouchedOnRest = (params) => {
    let newList = {};
    params.forEach((element) => {
      newList[element] = true;
    });
    // console.log(newList);
    setTouched(newList);
  };

  const checkForEmptyFields = (fields) => {
    let checkErrors = [];
    fields &&
      fields.forEach((field) => {
        if (!formData[field]) {
          checkErrors.push(field);
        }
      });
    // console.log(checkErrors)
    return checkErrors;
  };

  return {
    handleChange,
    handlePhoneChange,
    handleDateChange,
    // handleDateRangeChange,
    handleOnPhoneBlur,
    handleOnDateBlur,
    handleBlur,
    setTouchedOnRest,
    handleSubmit,
    formData,
    errors,
    touched,
    checkForEmptyFields,
  };
};

export default useForm;
