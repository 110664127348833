import { Link } from "react-router-dom";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
export const PostListWrapper = styled.div`
  .col-md-8 {
    padding-right: 0 !important;
  }
  &:nth-child(odd) {
    .news-article-content {
      padding-left: 1.5rem;
      padding-right: 0;
      //margin-left: 1.5rem;
    }
  }
  &:nth-child(even) {
    /* flex-direction: row-reverse; */
    //border: 1px solid red;
    .news-article-content {
      padding-left: 0;
      padding-left: 1.5rem;
      //margin-right: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    .col-md-4 {
      padding-left: 0;
      padding-right: 0;
    }
    .col-md-8 {
      padding-left: 0;
      padding-right: 0;
    }
    &:nth-child(even),
    &:nth-child(odd) {
      .news-article-content {
        padding: 0;
      }
    }
    .read-more-button {
      width: auto;
    }
  }
`;

export const TitleWrapper = styled.div.attrs((props) => ({
  className: `d-inline-block bg-warning ${props.propWidth}`,
}))`
  width: 50%;
  text-align: ${({ right }) => (right ? "right" : "")};

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DescriptionHeader = styled.h3`
  text-align: left;
  font-size: 2rem;
  font-weight: 900;
  color: var(--acolor) !important;
  /* font-family: ${({ theme }) =>
    theme.skin !== "pub" && `"Catamaran", Fallback, sans-serif`}; */
  position: relative;
  top: -1.6rem;
  text-transform: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "uppercase"};

  @media (max-width: 767px) {
    text-align: left;
    font-size: 1.7rem;
    line-height: 1.5;
    top: 1rem;
  }
`;

export const NextButton = styled(Link).attrs({
  className: "btn",
})`
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  font-weight: ${({ theme }) => theme.skin === "pub" && "400"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "0.93rem" : "12px")};
  width: ${({ newsprop }) => (newsprop ? "" : "140px")};
  float: right;
  color: var(--acolor) !important;
  padding-right: 0 !important;
  text-decoration: none !important;

  &:after {
    font-family: "Material Icons";
    content: "play_circle";
    vertical-align: middle;
    font-size: 1.7rem;
    padding-left: 1rem;
  }
  @media (max-width: 767px) {
    text-decoration: none !important;
  }
`;

export const NewsArticle = styled.div.attrs({
  className: "row",
})`
  align-items: center;
  border-bottom: ${({ theme }) =>
    theme.skin === "abc"
      ? "1px solid #0554b3"
      : "1px solid var(--footerBorder)"};
  padding: 3rem 0;
  .col-md-8 {
    padding-right: 0 !important;
  }

  &-image {
    //border: 1px solid red;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      /* bring your own prefixes */
      transform: translate(-50%, -50%);
      height: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: ${({ newsPub }) => (newsPub ? "2rem 0" : "3rem 0")};
    &:first-child {
      padding-top: ${({ theme }) => (theme.skin === "abc" ? "0" : "")};
    }
  }
`;

export const NewsArticleImage = styled.div.attrs({
  className: "col-md-4",
})`
  position: relative;
  overflow: hidden;
  z-index: 2;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    height: 100%;
  }
  border-radius: ${({ theme }) => (theme.skin === "rac" ? "" : "1rem")};
  height: 200px;

  @media (max-width: 767px) {
    height: 180px;
  }
`;

export const NewsContentWrapper = styled.div.attrs({
  className: "col-md-8 h-100 d-flex flex-column justify-content-between",
})`
  z-index: 2;
`;

export const NewsContent = styled.div.attrs({
  className: "d-flex flex-column justify-content-between news-article-content",
})``;

export const NewsTitle = styled.div`
  h3 {
    font-family: ${({ theme }) =>
      theme.skin !== "pub" && theme.skin === "fun" ? "RavagerSansRegular" : ""};
    font-size: ${({ theme }) => theme.skin === "pub" && "1rem"};
    font-weight: ${({ theme }) =>
      theme.skin === "fun" || theme.skin === "yak" ? "400" : "800"};
    text-align: left;
    width: 90%;
    text-transform: capitalize;
    color: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "fun" ||
      theme.skin === "rac"
        ? "var(--aboutH2Color)"
        : theme.skin === "pub"
          ? "#9A875D"
          : "var(--acolor)"};
    margin-bottom: 1rem;
    padding: 0;
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 1.2rem;
      line-height: 1.6;
      margin-top: 1rem;
    }
  }
  @media (min-width: 768px) and (max-width: 912px) {
    h3 {
      margin: 0.3rem 0 !important;
      font-size: 1.2rem !important;
    }
  }
`;

export const NewsText = styled.span`
  color: var(--newsText);
  margin-bottom: 0.5rem;
  /* font-family: "Catamaran", Fallback, sans-serif; */

  p {
    font-size: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "rac" || theme.skin === "qbc"
        ? "1rem"
        : "1.1rem"};
    line-height: 1.5;
    font-weight: 500;
    color: ${({ theme }) =>
      theme.skin === "abc"
        ? "#0554b3"
        : theme.skin === "fun"
          ? "#fff"
          : "var(--acolor)"};
    padding: 0 !important;
  }

  @media (max-width: 767px) {
    p {
      font-size: 1rem !important;
      line-height: 1.5 !important;
    }
  }
`;

export const ButtonWrapperRight = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

export const ButtonReadMore = styled(Link).attrs((props) => ({
  className:
    props.theme.skin === "pub" ||
    props.theme.skin === "cac" ||
    props.theme.skin === "hyc"
      ? ""
      : props.theme.skin === "rac"
        ? "btn btn-deposit"
        : "btn",
}))`
  text-decoration: none !important;
  width: 140px;
  float: right;
  text-align: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "bnk"
      ? "center"
      : "right"};
  font-size: ${({ theme }) => (theme.skin === "pub" ? "0.867rem" : "1rem")};
  font-weight: 900;
  padding: ${({ theme }) => (theme.skin === "rac" ? "" : "0 !important")};
  color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "bnk"
      ? "#fff !important"
      : theme.skin === "nbc"
        ? "#350e46"
        : theme.skin === "rac"
          ? "inherit"
          : "var(--acolor) !important"};

  background-color: ${({ theme }) => theme.skin === "nbc" && "#fff"};
  :hover {
    background-color: ${({ theme }) => theme.skin === "nbc" && "#1f0529"};
  }

  @media (max-width: 767px) {
    width: auto;
    font-size: 0.8rem !important;
  }
`;

export const PaginationUl = styled.ul.attrs({
  className: "pagination justify-content-center",
})`
  @media (max-width: 767px) {
    width: auto;
    font-size: 0.7rem;
  }
`;

export const ListWrapper = styled.div`
  width: ${isMobileOnly ? "180px" : "260px"};
  display: inherit;
  justify-content: center;
`;

export const PaginationLi = styled.li.attrs((props) => ({
  className: props.active ? "page-item active" : "page-item",
}))``;

export const PaginationArrowLeft = styled.span`
  &:after {
    font-family: "Material Icons";
    content: "chevron_left";
    vertical-align: bottom;
  }
`;

export const PaginationArrowRight = styled.span`
  &:after {
    font-family: "Material Icons";
    content: "chevron_right";
    vertical-align: bottom;
  }
`;

export const PaginationDoubleArrowLeft = styled.span`
  &:after {
    font-family: "Material Icons";
    content: "first_page";
    vertical-align: bottom;
  }
`;

export const PaginationDoubleArrowRight = styled.span`
  &:after {
    font-family: "Material Icons";
    content: "last_page";
    vertical-align: bottom;
  }
`;
