import { printAmount } from "common/src/helpers/functions";
import ProfileMenu from "./ProfileMenu";
import { useTranslation } from "react-i18next";
import { useScrollPosition } from "common/src/helpers/useScrollPosition";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import React, { useEffect } from "react";
import {
  ProfileRightWrapper,
  ProfileLinks,
  StyledButtonOpened,
  StyledMaterialIcon,
  UserInfoBalance,
  UserInfoBalanceTable,
  UserInfoBalanceWrapp,
  UserInfoExpandWrapper,
} from "../styledComponents/StyledHeader";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import LanguageChange from "./LanguageChange";

const UserInfo = ({
  player,
  headerUserExpand,
  balance,
  serverSetup,
  pages,
  profileMenu,
  setProfileMenu,
  isMyAccount,
  cancelWithdrawal,
  setNlLogoutLimitDialog,
  skin,
}) => {
  const { currency, country } = player;
  // console.log(country);
  const isUK = country === "gb" ? true : false;
  const isSE = country === "se" ? true : false;
  const { t, i18n } = useTranslation("");
  const currentLanguage = i18n.language || window.localStorage.i18nextLng;
  /* let location = useLocation();
   */
  useScrollPosition(
    ({ currPos }) => {
      const isShow = Math.abs(currPos.y) < 150;
      if (isShow === profileMenu) setProfileMenu(false);
    },
    [profileMenu]
  );

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("orientationchange", handleOrientationChange);
      return () =>
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
    }
  }, [handleOrientationChange]);

  let locationForTablet =
    location.pathname === pages["my-account"] ||
    location.pathname === pages["my-profile"] ||
    location.pathname === pages["cashback"] ||
    location.pathname === pages["bonuses"] ||
    location.pathname === pages["change-password"] ||
    location.pathname === pages["transactions"] ||
    location.pathname === pages["casino-history"] ||
    location.pathname === pages["my-limits"] ||
    location.pathname === pages["complaints"] ||
    location.pathname === pages["my-verification"];

  return (
    <UserInfoExpandWrapper headerUserExpand={headerUserExpand}>
      {skin === "plc" && balance && balance.pendingWithdrawal !== 0 && (
        <UserInfoBalance widrawal={balance.pendingWithdrawal !== 0}>
          <UserInfoBalanceWrapp>
            <div>
              {t("label_pending_withdrawal", "label_pending_withdrawal")}:{" "}
              {printAmount(
                balance && balance.pendingWithdrawal,
                currency,
                serverSetup.currencies,
                false
              )}
            </div>
            {!isSE && !isUK && (
              <StyledButton
                btn
                type="button"
                onClick={cancelWithdrawal}
                dangerouslySetInnerHTML={{
                  __html: t("label_cancel", "label_cancel"),
                }}
              ></StyledButton>
            )}
          </UserInfoBalanceWrapp>
        </UserInfoBalance>
      )}
      <UserInfoBalanceTable locationfortablet={locationForTablet ? 1 : 0}>
        <UserInfoBalanceWrapp>
          <div>
            {t("label_balance", "Balance")}:{" "}
            {skin === "pub" && isMobileOnly && <br></br>}
            {printAmount(
              balance && balance.balance ? balance.balance : 0,
              currency,
              serverSetup.currencies,
              false
            )}
          </div>
          {skin === "plc" && (
            <div>
              {t("label_reserve", "Reserve")}:{" "}
              {skin === "pub" && isMobileOnly && <br></br>}
              {printAmount(
                balance && balance.reserve ? balance.reserve : 0,
                currency,
                serverSetup.currencies,
                false
              )}
            </div>
          )}

          <div>
            {t("label_cash", "Cash")}:{" "}
            {skin === "pub" && isMobileOnly && <br></br>}
            {printAmount(
              balance && balance.real ? balance.real : 0,
              currency,
              serverSetup.currencies,
              false
            )}
          </div>
          <div>
            {t("label_bonus_header", "Bonus")}:{" "}
            {skin === "pub" && isMobileOnly && <br></br>}
            {printAmount(
              balance && balance.bonus ? balance.bonus : 0,
              currency,
              serverSetup.currencies,
              false
            )}
          </div>
          {skin !== "rac" && skin !== "nbc" && (
            <div>
              {t("label_sports_bonus_header", "SB Bonus")}:{" "}
              {skin === "pub" && isMobileOnly && <br></br>}
              {printAmount(
                balance && balance.sportsbookBonus
                  ? balance.sportsbookBonus
                  : 0,
                currency,
                serverSetup.currencies,
                false
              )}
            </div>
          )}
          {skin !== "plc" &&
            skin !== "pub" &&
            balance &&
            balance.pendingWithdrawal !== 0 && (
              <div>
                {t("label_pending_withdrawal")}:{" "}
                {printAmount(
                  balance && balance.pendingWithdrawal,
                  currency,
                  serverSetup.currencies,
                  false
                )}
              </div>
            )}
          {skin !== "plc" &&
            skin !== "pub" &&
            balance &&
            balance.pendingWithdrawal !== 0 &&
            !isSE &&
            !isUK && (
              <StyledButton
                btn
                cancelWithdrawal
                type="button"
                onClick={cancelWithdrawal}
                dangerouslySetInnerHTML={{
                  __html: t("label_cancel", "label_cancel"),
                }}
              ></StyledButton>
            )}
        </UserInfoBalanceWrapp>
      </UserInfoBalanceTable>
      {!isMobileOnly && !isTablet && !isMyAccount && (
        <StyledButtonOpened
          btnOpen={profileMenu}
          onClick={() => setProfileMenu(!profileMenu)}
        >
          <StyledMaterialIcon
            icon={profileMenu}
            dangerouslySetInnerHTML={{ __html: t("label_profile", "Profile") }}
          ></StyledMaterialIcon>
        </StyledButtonOpened>
      )}
      {!isMyAccount && (
        <ProfileMenu
          profileMenu={profileMenu}
          pages={pages}
          serverSetup={serverSetup}
          focusProfile={false}
        />
      )}
      <ProfileRightWrapper>
        <ProfileLinks
          locationfortablet={locationForTablet ? 1 : 0}
          to=""
          as="span"
          onClick={() =>
            skin === "plc"
              ? setNlLogoutLimitDialog(true)
              : (window.location = `/${currentLanguage}/logout`)
          }
          tablet={isTablet ? 1 : 0}
          dangerouslySetInnerHTML={{ __html: t("label_logout", "Logout") }}
        ></ProfileLinks>
        <ProfileLinks
          to={skin === "rac" ? pages["my-profile"] : pages["my-account"]}
          locationfortablet={locationForTablet ? 1 : 0}
          tablet={isTablet ? 1 : 0}
          dangerouslySetInnerHTML={
            skin === "rac"
              ? {
                  __html: t("label_my_profile", "My Profile"),
                }
              : {
                  __html: t("label_my_account", "My Account"),
                }
          }
        ></ProfileLinks>
        <ProfileLinks
          to={pages["withdrawal"]}
          locationfortablet={locationForTablet ? 1 : 0}
          tablet={isTablet ? 1 : 0}
          dangerouslySetInnerHTML={{ __html: t("label_withdraw", "Withdraw") }}
        ></ProfileLinks>
        {(skin === "nbc" ||
          skin === "yet" ||
          skin === "fun" ||
          skin === "yak") && (
          <LanguageChange
            serverSetup={serverSetup}
            userInfoProp={true}
            funUser
          />
        )}
      </ProfileRightWrapper>
      {skin === "pub" && <hr></hr>}
    </UserInfoExpandWrapper>
  );
};

export default UserInfo;
