import { isMobileOnly } from "react-device-detect";
import styled from "styled-components";

export const NewsWrapper = styled.div`
  max-width: 1024px;
  color: #fff;
  text-align: left;
  margin: ${isMobileOnly ? "0 1rem 1rem 1rem" : "2rem 1rem"};
  font-size: 1rem;

  img {
    width: 100%;
  }
  p {
    color: var(--newsTextColor);
  }
  h1,
  h2 {
    color: var(--newsHeaderColor);
  }

  a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`;

export const TextNews = styled.div`
  text-align: ${({ theme }) => theme.skin === "rac" && "center"};
  h3 {
    margin: 1rem;
  }
  ol,
  ul {
    color: #fff;
  }
`;

export const NextAndBackNews = styled.div.attrs({
  className: "news-nav d-flex justify-content-between mt-5",
})`
  text-transform: capitalize;
  font-size: 1rem;
`;

export const NewsButtonWrapper = styled.div.attrs((props) => ({
  className: props.sports
    ? "my-3 d-flex justify-content-around"
    : "mt-3 d-flex justify-content-center",
}))`
  position: ${({ sports }) =>
    sports && (isMobileOnly ? "relative" : "absolute")};
  top: ${({ sports }) => sports && (isMobileOnly ? "" : "-150px")};
  max-width: ${({ sports }) => sports && !isMobileOnly && "1024px"};
  width: ${({ sports }) => sports && !isMobileOnly && "100%"};
  flex-wrap: ${({ sports }) => isMobileOnly && sports && "wrap"};
  button:hover {
    background-color: ${({ theme }) => theme.skin === "nbc" && "#1f0529"};
  }
  button {
    width: ${({ theme }) => theme.skin === "rac" && "100%"};
  }
`;

export const NewsSprotsBanner = styled.div`
  padding: ${isMobileOnly && "110px 0 1rem 0 "};
  opacity: 0.5;
`;

export const NewsSprotsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${isMobileOnly ? "100%" : "calc(50% - 3rem)"};
  margin: ${isMobileOnly ? "1rem 0" : "1.5rem"};
  border-radius: 0.5rem;
  border: 1px solid var(--tournamentsBorder);

  h1,
  div {
    margin: 1rem;
    text-align: left;
  }
  p {
    color: var(--newsTextColor);
  }
  h2 {
    color: ${({ theme }) => theme.skin === "qbc" && "var(--newsHeaderColor)"};
  }
`;

export const NewsSportsBtnWrapper = styled.div`
  margin: 1rem;
  width: 100%;
  button {
    margin: 0 1rem 0 0;
  }
`;
