import { LicensesList } from "./LicensesList";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo18 from "common/src/assets/img/logo_18.svg";
import FooterLinks from "./FooterLinks";
import {
  FooterWrapper,
  FooterLinksWrapper,
  FooterBottomSection,
  StyledFaderBottom,
  StyledFooterBootom,
  StyledTrustlyline,
  Logo18,
  FotterLicences,
  Copyrightght,
  PUBFooterImg,
  CacSectionWrapper,
  PUBFooterImgSafari,
} from "../styledComponents/StyledFooter";
import { NewsButtonWrapper } from "../styledComponents/StyledNews";
import React, { useEffect, useState } from "react";
import { StyledFader } from "../styledComponents/StyledIndex";
import { StyledProviderLogo } from "../styledComponents/StyledGame";
import PaymentMethods from "./PaymentMethods";
import { GameProvidersList } from "common/src/component/GameProvidersList";
import FooterText from "./FooterText";
import { DividerPub } from "../styledComponents/StyledSlider";
import { THEME } from "../helpers/constants";
import RacTrastlyline from "./RacTrystlyline";
import { browserVersion, isMobileSafari, isSafari } from "react-device-detect";

const Footer = ({
  currentTheme,
  setTheme,
  serverSetup,
  gameCategories,
  pages,
  setSearchResults,
  accountRoute,
  player,
  setSpinner,
  setSelectedCategory,
  config,
  imgAndDevider,
  trastlyline,
  styleOne,
  styleTwo,
  logoFooter,
  footerText,
  providerBg,
  footerFader,
  setSelectedProvider,
}) => {
  const { t, i18n } = useTranslation("");
  let location = useLocation();
  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || config.defaultLang
  }`;
  const [forcePng, setForcePng] = useState(false);

  useEffect(() => {
    if ((isMobileSafari || isSafari) && parseInt(browserVersion) < 14)
      setForcePng(true);
  }, []);

  let noTopBorderOnGames = "footer-padding";
  if (
    location.pathname === `${baseUrl}/games` ||
    matchPath(location.pathname, gameCategories) ||
    location.pathname === `${baseUrl}/withdrawal`
  ) {
    noTopBorderOnGames = "footer-padding-game";
  }

  const switchTheme = () => {
    const nextTheme = currentTheme === "light" ? "dark" : "light";
    setTheme(nextTheme);
    document.documentElement.setAttribute("data-theme", nextTheme);
    localStorage.setItem(THEME, nextTheme);
  };

  document.documentElement.setAttribute("data-theme", currentTheme);

  return (
    <FooterWrapper
      game={
        location.pathname === `${baseUrl}/games` ||
        location.pathname === `${baseUrl}/lp-roulette` ||
        matchPath(location.pathname, gameCategories) ||
        location.pathname === `${baseUrl}/withdrawal`
      }
    >
      {!styleTwo && (
        <FooterLinksWrapper>
          {imgAndDevider &&
            (forcePng ? <PUBFooterImgSafari /> : <PUBFooterImg />)}
          {imgAndDevider && <DividerPub footer />}
          <FooterLinks
            player={player}
            pages={pages}
            skin={config.skin}
            switchTheme={switchTheme}
            currentTheme={currentTheme}
            server={serverSetup}
          />
        </FooterLinksWrapper>
      )}
      {trastlyline && <RacTrastlyline />}
      {styleOne && (
        <StyledFader>
          <PaymentMethods
            currentTheme={currentTheme}
            pages={pages}
            serverSetup={serverSetup}
            currentLanguage={serverSetup.currentLanguage}
            skin={config.skin}
            paymentsImagePath={config.paymentsImagePath}
          />
        </StyledFader>
      )}
      {styleOne && (
        <StyledProviderLogo background={providerBg}>
          <GameProvidersList
            currentTheme={currentTheme}
            serverSetup={serverSetup}
            skin={config.skin}
            vendorsImagePath={config.vendorsImagePath}
            setSearchResults={setSearchResults}
            accountRoute={accountRoute}
            setSpinner={setSpinner}
            setSelectedCategory={setSelectedCategory}
            setSelectedProvider={setSelectedProvider}
            pages={pages}
          />
        </StyledProviderLogo>
      )}
      {styleOne && <FooterText skin={config.skin} />}
      {styleTwo && (
        <CacSectionWrapper>
          <FooterLinksWrapper>
            <FooterLinks
              player={player}
              pages={pages}
              skin={config.skin}
              switchTheme={switchTheme}
              currentTheme={currentTheme}
              server={serverSetup}
            />
          </FooterLinksWrapper>
          <FooterText skin={config.skin} />
        </CacSectionWrapper>
      )}
      <FooterBottomSection>
        {footerFader && <StyledFaderBottom />}
        <StyledFooterBootom>
          <NewsButtonWrapper>
            <StyledTrustlyline>
              {logoFooter && (
                <Logo18>
                  <img alt="" src={logo18} height="40px" />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "label_footer-text-top",
                        `Gambling can be addictive, <br>play responsibily</br>`
                      ),
                    }}
                  ></div>
                </Logo18>
              )}
              {footerText && (
                <FooterText skin={config.skin} companyInfo={true} />
              )}
              <FotterLicences background={providerBg}>
                <LicensesList skin={config.skin} />
              </FotterLicences>

              <Copyrightght>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("label_text_copyright"),
                  }}
                ></p>
                <p>&middot;</p>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("label_footer_text_bottom"),
                  }}
                ></p>
              </Copyrightght>
            </StyledTrustlyline>
          </NewsButtonWrapper>
        </StyledFooterBootom>
      </FooterBottomSection>
    </FooterWrapper>
  );
};

export default Footer;
