import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import {
  QUERY_GET_GAMES_FOR_EXCLUD,
  QUERY_GET_BLOCKED_GAMES,
  QUERY_GET_GAME_TYPES_FOR_EXCLUSION,
  QUERY_GET_BLOCKED_GAME_TYPES,
} from "common/src/graphql/queries";
import {
  MUTATION_BLOCK_GAME_BY_ID,
  MUTATION_BLOCK_GAME_TYPE,
} from "common/src/graphql/mutations";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GameExclusionConfirm } from "../modal/GameExclusionConfirm";
import { GameTypeExclusionConfirm } from "../modal/GameTypeExclusionConfirm";
import { isMobile } from "react-device-detect";
import {
  BlockedGamesTableCell,
  ExcludedGamesWrapper,
  SectionExcludedGameWeapper,
  StyledBlockedGames,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledFlexTableCenter,
  StyledFormCheckInline,
  StyledFormCheckLabel,
  StyledFormGroup,
} from "../styledComponents/CommonStyledComponents";
import {
  AccountDescription,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  TableHeader,
} from "../styledComponents/AccountPage";

const ExcludedGames = ({ player, pages, realityCheck, currentTheme, skin }) => {
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();
  const { country } = player;

  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedGameTypes, setSelectedGameTypes] = useState([]);
  const [efgPeriod, setEfgPeriod] = useState("");
  const [efgPeriodType, setEfgPeriodType] = useState("");
  const [blockedGames, setBlockedGames] = useState([]);
  const [blockedGameType, setBlockedGameType] = useState([]);
  const [showGameExclusionConfirm, setShowGameExclusionConfirm] =
    useState(false);
  const [showGameTypeExclusionConfirm, setShowGameTypeExclusionConfirm] =
    useState(false);
  const [displayOptions, setDisplayOptions] = useState(false);
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  // Get games from API
  const loadOptions = async (input) => {
    if (input && input.trim().length < 4) {
      setDisplayOptions(false);
      return [];
    } else {
      setDisplayOptions(true);
      //console.log(input);
      const { data } = await apolloClient.query({
        query: QUERY_GET_GAMES_FOR_EXCLUD,
        variables: {
          query: input,
          device: isMobile ? "mob" : "desk",
          country: country ? country : "rs",
          themeSearch: "false",
          vendorSearch: "false",
          subVendorSearch: "false",
        },

        // fetchPolicy: 'cache-first',
      });
      if (data) {
        return data.searchGames.games.map((game) => ({
          label: game.name + " (" + game.vendor + ")",
          value: game.internalID,
        }));
      }

      return [];
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) setDisplayOptions(false);
  };

  const handleChange = (opt) => {
    setSelectedGames(opt);
    setDisplayOptions(false);
  };

  // Get games from API
  const loadTypesOptions = async () => {
    //console.log('load types');
    // if (input && input.trim().length < 4) {
    //   return [];
    // } else {
    //console.log(input);
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_GAME_TYPES_FOR_EXCLUSION,
      // fetchPolicy: 'cache-first',
    });

    if (loading) {
      //console.log('loading');
      return [];
    }

    if (errors) {
      //console.log('errors');
      return [];
    }

    if (data) {
      //console.log('success');
      //console.log(data);
      //console.log(data.getGameTypes.gameTypes);
      return data.getGameTypes.gameTypes.map((gameType) => ({
        label: gameType.name,
        value: gameType.name,
      }));
    }

    return [];
    // }
  };

  const doGameExclusion = async () => {
    //console.log(JSON.stringify(selectedGames) + ' ' + efgPeriod);
    let blGame = {};
    selectedGames.forEach((game) => {
      blGame = {
        gameID: game.value,
        //gameName: game.label.replace(/ *\([^)]*\) */g, ''),
        duration: parseInt(efgPeriod),
      };
      blockGame(blGame);
    });
  };

  const doGameTypeExclusion = async () => {
    //console.log(JSON.stringify(selectedGameTypes) + ' ' + efgPeriodType);
    let blType = {};
    selectedGameTypes.forEach((gameType) => {
      blType = {
        name: gameType.label,
        duration: parseInt(efgPeriodType),
      };
      blockGameType(blType);
    });
  };

  async function blockGame(game) {
    const { loading, errors, data } = await apolloClient.mutate({
      mutation: MUTATION_BLOCK_GAME_BY_ID,
      variables: game,
      errorPolicy: "all",
    });
    if (loading) return "Loading ...";
    if (errors) {
      toast.error(errors[0].message, {
        position: "bottom-center",
      });
      return false;
    } else
      data &&
        toast.success(t("suc_limit_message"), {
          position: "bottom-center",
        });
    window.location = pages["my-limits"];
    return true;
  }

  async function blockGameType(gameType) {
    const { loading, errors, data } = await apolloClient.mutate({
      mutation: MUTATION_BLOCK_GAME_TYPE,
      variables: gameType,
      errorPolicy: "all",
    });
    if (loading) return "Loading ...";
    if (errors) {
      toast.error(errors[0].message, {
        position: "bottom-center",
      });
      return false;
    } else
      data &&
        toast.success(t("suc_limit_message"), {
          position: "bottom-center",
        });
    window.location = pages["my-limits"];
    return true;
  }

  async function getBlockedGams() {
    //QUERY_GET_BLOCKED_GAMES
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_BLOCKED_GAMES,
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;
    setBlockedGames(data.getBlockedGames.games);
    return null;
  }

  async function getBlockedGameTypes() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_BLOCKED_GAME_TYPES,
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;
    setBlockedGameType(data.getBlockedGameTypes.types);
    return null;
  }

  useEffect(() => {
    getBlockedGams();
    getBlockedGameTypes();
  }, []);

  const customStyle = {
    option: (provided) => ({
      ...provided,
      // color: '#fff',
      border: currentTheme === "dark" ? "#172e4e" : "#eff3f5",
      color:
        currentTheme === "dark" ||
        skin === "nbc" ||
        skin === "yet" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak" ||
        skin === "qbc"
          ? "#fff"
          : skin === "fun"
            ? "#186289"
            : "#0e1c81",
      backgroundColor:
        currentTheme === "dark"
          ? `${
              skin === "plc"
                ? "#172e4e"
                : skin === "pub"
                  ? "#222222"
                  : "#1a1a2d"
            }`
          : skin === "nbc"
            ? "#350f46"
            : skin === "yet"
              ? "#18577c"
              : skin === "fun"
                ? "#fce5b9"
                : skin === "cac"
                  ? "#302d2e"
                  : skin === "hyc" || skin === "yak" || skin === "qbc"
                    ? "var(--inputbgABC)"
                    : "#eff3f5",
    }),
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: currentTheme === "dark" ? "#0a2142" : "#E6E6E6",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: currentTheme === "dark" ? "#fff" : "#172e4e",
    }),
    menu: (base) => ({
      ...base,
      color: "#fff",
      borderRadius: "0",
    }),
    input: (base) => ({
      ...base,
      color:
        currentTheme === "dark"
          ? "#fff"
          : `${
              skin === "plc"
                ? "#0e1c81"
                : skin === "nbc" ||
                    skin === "yet" ||
                    skin === "yak" ||
                    skin === "qbc"
                  ? "#fff"
                  : skin === "fun" ||
                      skin === "cac" ||
                      skin === "hyc" ||
                      skin === "rac"
                    ? "var(--acolor)"
                    : "#0554b3"
            }`,
      padding: `${
        skin === "pub"
          ? "0.75rem 0.75rem"
          : skin === "abc" ||
              skin === "nbc" ||
              skin === "bnk" ||
              skin === "yet" ||
              skin === "fun" ||
              skin === "qbc"
            ? "0.5rem 0.5rem"
            : ""
      }`,
    }),
    placeholder: (base) => ({
      ...base,
      color:
        currentTheme === "dark"
          ? "#fff"
          : `${
              skin === "plc"
                ? "#0e1c81"
                : skin === "nbc" ||
                    skin === "yet" ||
                    skin === "yak" ||
                    skin === "qbc"
                  ? "#fff"
                  : skin === "fun" ||
                      skin === "cac" ||
                      skin === "hyc" ||
                      skin === "rac"
                    ? "var(--acolor)"
                    : "#0554b3"
            }`,
    }),
    noOptionsMessage: (base) => ({
      ...base,
      color:
        currentTheme === "dark"
          ? "#fff"
          : `${
              skin === "plc"
                ? "#0e1c81"
                : skin === "nbc" ||
                    skin === "yet" ||
                    skin === "yak" ||
                    skin === "qbc"
                  ? "#fff"
                  : skin === "fun" ||
                      skin === "cac" ||
                      skin === "hyc" ||
                      skin === "rac"
                    ? "var(--acolor)"
                    : "#0554b3"
            }`,
      backgroundColor:
        currentTheme === "dark"
          ? `${
              skin === "plc"
                ? "#172e4e"
                : skin === "pub"
                  ? "#222222"
                  : "#1a1a2d"
            }`
          : skin === "nbc"
            ? "#350f46"
            : skin === "yet"
              ? "#18577c"
              : skin === "fun"
                ? "#fce5b9"
                : skin === "cac"
                  ? "#302d2e"
                  : skin === "hyc" || skin === "yak" || skin === "qbc"
                    ? "var(--inputbgABC)"
                    : "#eff3f5",
    }),
    loadingMessage: (base) => ({
      ...base,
      color:
        currentTheme === "dark"
          ? "#fff"
          : `${
              skin === "plc"
                ? "#0e1c81"
                : skin === "nbc" ||
                    skin === "yet" ||
                    skin === "yak" ||
                    skin === "qbc"
                  ? "#fff"
                  : skin === "fun" ||
                      skin === "cac" ||
                      skin === "hyc" ||
                      skin === "rac"
                    ? "var(--acolor)"
                    : "#0554b3"
            }`,
      backgroundColor:
        currentTheme === "dark"
          ? `${
              skin === "plc"
                ? "#172e4e"
                : skin === "pub"
                  ? "#222222"
                  : "#1a1a2d"
            }`
          : skin === "nbc"
            ? "#350f46"
            : skin === "yet"
              ? "#18577c"
              : skin === "fun"
                ? "#fce5b9"
                : skin === "cac"
                  ? "#302d2e"
                  : skin === "hyc" || skin === "yak" || skin === "qbc"
                    ? "var(--inputbgABC)"
                    : "#eff3f5",
    }),
  };
  let noOptionsText = "label_no_results_found";

  return (
    <>
      <ExcludedGamesWrapper>
        <AccountDescription
          expand={true}
          dangerouslySetInnerHTML={{
            __html: t("label_excluded_games_description"),
          }}
        ></AccountDescription>
        {skin === "plc" && (
          <SectionExcludedGameWeapper excludedlimit={"rounded-bottom"}>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </SectionExcludedGameWeapper>
        )}
        <SectionExcludedGameWeapper
          excludedlimits={
            skin === "rac" &&
            "border-top border-left border-right border-bottom-0"
          }
        >
          <StyledFormGroup margin={"mt-5"} col={"col-md-8"}>
            {/*<label>{t('label_choose_limit')}</label>*/}
            <AsyncSelect
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              /* cacheOptions */
              loadOptions={loadOptions}
              onChange={(opt) => handleChange(opt)}
              isMulti
              placeholder={t("label_select_games")}
              classNamePrefix={"custom-input"}
              styles={customStyle}
              menuIsOpen={displayOptions}
              onBlur={() => setDisplayOptions(false)}
              onKeyDown={handleKeyDown}
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? noOptionsText : t("label_no_results_found")
              }
              loadingMessage={() => t("label_loading")}
            />
          </StyledFormGroup>
        </SectionExcludedGameWeapper>
        <SectionExcludedGameWeapper
          spanStyle
          excludedlimits={
            skin === "rac"
              ? "border-left border-right"
              : skin === "abc" ||
                  skin === "nbc" ||
                  skin === "bnk" ||
                  skin === "yet"
                ? "mt-2"
                : ""
          }
        >
          <StyledFormCheckInline mt>
            <StyledFormCheckLabel margin>
              {t("label_time_period")}
            </StyledFormCheckLabel>
            <StyledFormCheckLabel>
              <input
                type="radio"
                className="form-check-input"
                name="optradio"
                value="1"
                checked={efgPeriod === "1"}
                onChange={(e) => setEfgPeriod(e.target.value)}
              />
              {t("message_efg_24_hours")}
            </StyledFormCheckLabel>
          </StyledFormCheckInline>
          <StyledFormCheckInline>
            <StyledFormCheckLabel>
              <input
                type="radio"
                className="form-check-input"
                name="optradio"
                value="7"
                checked={efgPeriod === "7"}
                onChange={(e) => setEfgPeriod(e.target.value)}
              />
              {t("message_efg_1_week")}
            </StyledFormCheckLabel>
          </StyledFormCheckInline>
          <StyledFormCheckInline disabled>
            <StyledFormCheckLabel>
              <input
                type="radio"
                className="form-check-input"
                name="optradio"
                value="30"
                checked={efgPeriod === "30"}
                onChange={(e) => setEfgPeriod(e.target.value)}
              />
              {t("message_efg_1_month")}
            </StyledFormCheckLabel>
          </StyledFormCheckInline>
        </SectionExcludedGameWeapper>
        <SectionExcludedGameWeapper
          excludedlimits={
            skin === "rac"
              ? "py-3 border-left border-right border-bottom-0"
              : "py-5"
          }
        >
          <StyledButton
            selfExcludionProp
            type="submit"
            pub={skin === "pub"}
            onClick={() => setShowGameExclusionConfirm(true)}
            disabled={efgPeriod === "" || selectedGames === null}
          >
            {t("label_submit_game_block")}
          </StyledButton>
        </SectionExcludedGameWeapper>
        {skin === "rac" && (
          <SectionExcludedGameWeapper
            excludedlimits={" border-left border-right"}
          >
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </SectionExcludedGameWeapper>
        )}
        {blockedGames.length > 0 && (
          <StyledBlockedGames>
            <BlockedGamesTableCell>
              {t("label_efg_game_name")}
            </BlockedGamesTableCell>
            <BlockedGamesTableCell>
              {t("label_efg_exclusion_start_date")}
            </BlockedGamesTableCell>
            <TableHeader col={"col-md-2"}>
              {t("label_efg_exclusion_end_date")}
            </TableHeader>
          </StyledBlockedGames>
        )}
        {blockedGames.length > 0 &&
          blockedGames.map((game, index) => (
            <StyledFlexTableCenter key={index}>
              <StyledTableCell cell={"topic-cell"} col={" col-md-4"}>
                <StyledTableContent>{game.gameName}</StyledTableContent>
              </StyledTableCell>
              <StyledTableCell cell={"type-cell"} col={" col-md-4"}>
                <StyledTableHeading>
                  {t("label_efg_exclusion_start_date")}
                </StyledTableHeading>
                <StyledTableContent>
                  {new Date(game.blockedAt)
                    .toLocaleString(localizedFormat)
                    .replace(/-/g, "/")}
                </StyledTableContent>
              </StyledTableCell>
              <StyledTableCell cell={"amount-cell"} col={" col-md-2"}>
                <StyledTableHeading>
                  {t("label_efg_exclusion_end_date")}
                </StyledTableHeading>
                <StyledTableContent>
                  {new Date(game.expiresAt)
                    .toLocaleString(localizedFormat)
                    .replace(/-/g, "/")}
                </StyledTableContent>
              </StyledTableCell>
            </StyledFlexTableCenter>
          ))}
        <GameExclusionConfirm
          showModal={showGameExclusionConfirm}
          handleProceed={() => doGameExclusion()}
          handleCancel={() => setShowGameExclusionConfirm(false)}
          skin={skin}
        />

        {country === "nl" && (
          <>
            <SectionExcludedGameWeapper style={{ marginTop: "2rem" }}>
              <StyledFormGroup
                margin={skin === "plc" ? "mt-5" : "mt-0"}
                col={"col-md-8"}
              >
                {/*<label>{t('label_choose_limit')}</label>*/}
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadTypesOptions}
                  defaultOptions={true}
                  onChange={(opt) => setSelectedGameTypes(opt)}
                  isMulti
                  placeholder={t("label_select_game_types")}
                  classNamePrefix={"custom-input"}
                  styles={customStyle}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue ? noOptionsText : t("label_no_results_found")
                  }
                />
              </StyledFormGroup>
            </SectionExcludedGameWeapper>
            <SectionExcludedGameWeapper
              spanStyle
              excludedlimits={skin === "abc" && "mt-2"}
            >
              <StyledFormCheckInline>
                <StyledFormCheckLabel margin>
                  {t("label_time_period")}
                </StyledFormCheckLabel>
                <StyledFormCheckLabel>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="optradioType"
                    value="1"
                    checked={efgPeriodType === "1"}
                    onChange={(e) => setEfgPeriodType(e.target.value)}
                  />
                  {t("message_efg_24_hours")}
                </StyledFormCheckLabel>
              </StyledFormCheckInline>
              <StyledFormCheckInline>
                <StyledFormCheckLabel>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="optradioType"
                    value="7"
                    checked={efgPeriodType === "7"}
                    onChange={(e) => setEfgPeriodType(e.target.value)}
                  />
                  {t("message_efg_1_week")}
                </StyledFormCheckLabel>
              </StyledFormCheckInline>
              <StyledFormCheckInline disabled>
                <StyledFormCheckLabel>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="optradioType"
                    value="30"
                    checked={efgPeriodType === "30"}
                    onChange={(e) => setEfgPeriodType(e.target.value)}
                  />
                  {t("message_efg_1_month")}
                </StyledFormCheckLabel>
              </StyledFormCheckInline>
            </SectionExcludedGameWeapper>
            <SectionExcludedGameWeapper excludedlimits={"py-5"}>
              <StyledButton
                selfExcludionProp
                type="submit"
                onClick={() => setShowGameTypeExclusionConfirm(true)}
                disabled={efgPeriodType === "" || selectedGameTypes === null}
              >
                {t("label_submit_type_block")}
              </StyledButton>
            </SectionExcludedGameWeapper>
            {blockedGameType.length > 0 && (
              <StyledBlockedGames>
                <BlockedGamesTableCell>
                  {t("label_efgt_game_type")}
                </BlockedGamesTableCell>
                <BlockedGamesTableCell>
                  {t("label_efg_exclusion_start_date")}
                </BlockedGamesTableCell>
                <TableHeader col={"col-md-2"}>
                  {t("label_efg_exclusion_end_date")}
                </TableHeader>
              </StyledBlockedGames>
            )}
            {blockedGameType.length > 0 &&
              blockedGameType.map((gameType, index) => (
                <SectionExcludedGameWeapper key={index}>
                  <StyledTableCell cell={"topic-cell"} col={" col-md-4"}>
                    <StyledTableContent>{gameType.name}</StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell cell={"type-cell"} col={" col-md-4"}>
                    <StyledTableHeading>
                      {t("label_efg_exclusion_start_date")}
                    </StyledTableHeading>
                    <StyledTableContent>
                      {new Date(gameType.blockedAt)
                        .toLocaleString(localizedFormat)
                        .replace(/-/g, "/")}
                    </StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell cell={"amount-cell"} col={" col-md-2"}>
                    <StyledTableHeading>
                      {t("label_efg_exclusion_end_date")}
                    </StyledTableHeading>
                    <StyledTableContent>
                      {new Date(gameType.expiresAt)
                        .toLocaleString(localizedFormat)
                        .replace(/-/g, "/")}
                    </StyledTableContent>
                  </StyledTableCell>
                </SectionExcludedGameWeapper>
              ))}
            <GameTypeExclusionConfirm
              showModal={showGameTypeExclusionConfirm}
              handleProceed={() => doGameTypeExclusion()}
              handleCancel={() => setShowGameTypeExclusionConfirm(false)}
              skin={skin}
            />
          </>
        )}
      </ExcludedGamesWrapper>
      {skin !== "rac" && skin !== "fun" && (
        <AccountDescription
          expand="true"
          description
          dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
        ></AccountDescription>
      )}
    </>
  );
};

export default ExcludedGames;
