import styled from "styled-components";
import IFrame from "common/src/component/IFrame";
import BubbleFirs from "./../../../abc/src/data/icon-component/register-buble.svg";
import BubbleFirsFill from "./../../../abc/src/data/icon-component/register-buble-fill.svg";
import Bubble from "./../../../abc/src/data/icon-component/register-buble-second.svg";
import BubbleFill from "./../../../abc/src/data/icon-component/register-buble-second-fill.svg";
import BubbleLast from "./../../../abc/src/data/icon-component/register-buble-last.svg";
import BubbleLastFill from "./../../../abc/src/data/icon-component/register-buble-last-fill.svg";
import BubblePUB from "./../../../pub/src/data/icon-component/register-buble-pub.svg";
import BubblePUBActive from "./../../../pub/src/data/icon-component/register-buble-pub-active.svg";
import BubbleNBCActive from "./../../../nbc/src/data/icon-component/register-buble-nbc-active.svg";
import BubbleBNKActive from "./../../../bnk/src/data/icon-component/register-buble-bnk-active.svg";
import BubbleFUNActive from "./../../../fun/src/data/icon-component/register-buble-fun-active.svg";
import BubbleCACActive from "./../../../cac/src/data/icon-component/register-buble-cac.svg";
import BubbleRACActive from "./../../../rac/src/data/icon-component/register-buble-rac.svg";
import BubbleQBCActive from "./../../../pub/src/data/icon-component/register-buble-qbc-active.svg";
import BubbleQBC from "./../../../pub/src/data/icon-component/register-buble-qbc.svg";

export const ResetWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "plc"
      ? "col-lg-5 col-md-8 col-sm-12 my-0 mx-auto"
      : props.theme.skin === "pub" ||
          props.theme.skin === "cac" ||
          props.theme.skin === "hyc"
        ? "col-xl-4 col-md-6 col-sm-12 offset-xl-4 offset-md-3"
        : "col-md-6 col-sm-12 offset-md-3",
}))``;

export const TitlePagesHeader = styled.h3`
  font-size: 22px;
  font-weight: 800;
  padding: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "1rem 0"
      : "0 0 1rem 0"};
  color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "rac"
      ? "var(--smallHeaderABC)"
      : "var(--tabletext)"};
  margin-bottom: ${({ theme }) => theme.skin === "abc" && "0"};
`;

export const ResetPageheadlinePar = styled.p`
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "var(--smallHeaderABC)"
      : theme.skin === "rac"
        ? "#fff"
        : "var(--tabletext)"};
`;

export const ResetFormGroup = styled.div.attrs((props) => ({
  className: `form-group mb-3 text-left ${props.mt ? "mt-5" : ""}`,
}))`
  color: var(--tabletext);
`;

export const ResetButtonWrapper = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "plc"
      ? props.extrapadding
        ? "d-flex justify-content-center pt-5"
        : "d-flex justify-content-center pt-3"
      : props.gdpr
        ? "d-flex justify-content-around mt-4"
        : "d-flex justify-content-center mt-4",
}))`
  ${(props) => {
    /* if (props.theme.skin === "abc") {
      return `
      margin-top: 1rem;
      @media (max-width: 767px) {
        button {
          font-size: 0.8rem;
          max-width: 120px;
        }
      }
      `;
    } */
    if (
      props.theme.skin === "pub" ||
      props.theme.skin === "cac" ||
      props.theme.skin === "hyc"
    ) {
      return `
      margin-top: 1rem;
      @media (max-width: 767px) {
        button {
          font-size: 0.8rem;
          max-width: 140px;
        }
      }
      `;
    }
  }}
`;

export const ForgotFormGroup = styled.div.attrs({
  className: "form-group my-5 text-left",
})``;

export const LoginButtonWrapper = styled.div.attrs({
  className: "d-flex flex-column align-items-center",
})`
  .btn {
    padding: ${({ theme }) => (theme.skin === "abc" ? "1rem 0" : "")};
  }
  a {
    color: ${({ theme }) =>
      theme.skin === "fun" ? "#fa2c70" : theme.skin === "yet" ? "#ffff" : ""};

    :hover {
      color: ${({ theme }) => theme.skin === "yet" && "#5ec4f8"};
    }
  }
`;

export const StyledAlert = styled.div.attrs((props) => ({
  className: props.alert
    ? "text-center alert alert-success"
    : "text-center alert alert-danger",
}))``;

export const RegisterOffset = styled.form.attrs({
  className: "col-md-8 offset-md-2",
})``;

export const StyledRegisterFormGroup = styled.div.attrs((props) => ({
  className: props.step ? "form-group mb-3 " : "hidden",
}))`
  label {
    text-transform: none;
  }
`;

export const StyledRegisterFormGroupFlex = styled.div.attrs((props) => ({
  className: props.step ? "flex-table mt-3" : "hidden",
}))``;

export const PnPDescriptionPar = styled.p.attrs({
  className: "mt-3",
})`
  display: block;
  text-align: center;
  color: var(--labelcolor);
  font-size: 14px;
  margin: auto;
  padding-bottom: ${({ pagination }) => pagination && "2rem"};
`;

export const StyledTriggerValidation = styled.div.attrs((props) => ({
  className: props.step ? "d-flex justify-content-between mt-3" : "hidden",
}))``;

export const StyledPnPStatusWrapper = styled.div.attrs({
  className: "flex-table-row row flex-table-row-header d-sm-flex",
})``;

export const StyledPnPStatusTopicCell = styled.div.attrs({
  className: "flex-table-cell topic-cell col-sm-12 col-md-3",
})``;

export const StyledPnPInvalidTC = styled.div.attrs({
  className: "flex-table-cell col-sm-12 col-md-7",
})``;

export const StyledCustomCheckboxWrapper = styled.div.attrs({
  className: "custom-control custom-checkbox",
})`
  .custom-control-label {
    &::before {
      border: ${({ affordability, theme }) =>
        affordability &&
        (theme.skin === "rac"
          ? "1px solid var(--inputbordercolorABC)"
          : "2px solid #7d7d7d")};
    }
  }
  margin-top: ${({ affordability }) => affordability && "2rem"};
`;

export const StyledPnPStatusCell = styled.div.attrs((props) => ({
  className: props.second
    ? "flex-table-cell status s12 col-md-2"
    : "flex-table-cell col-sm-12 col-md-2 status",
}))``;

export const FormGroupNameWrapper = styled.div.attrs((props) => ({
  className: props.step ? "form-group" : "hidden",
}))``;

export const PnPColWrapper = styled.div.attrs({
  className:
    "flex-table-row row flex-table-row-header table-header d-none d-sm-flex",
})``;

export const NewWindowStep = styled.div.attrs((props) => ({
  className: "text-center",
}))`
  /* color: var(--tabletext);

  .btn {
    margin-top: 3vh;
  } */
`;

export const NewWindowStepDescription = styled.span.attrs((props) => ({
  className: "col-sm-12",
}))`
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme }) =>
    theme.skin === "abc" ? "var(--smallHeaderABC)" : "var(--tabletext)"};
  margin-bottom: 40px;
`;

export const RegisterStepWrapper = styled.div.attrs((props) => ({
  className: props.idinstep ? "col-md-12" : "col-md-6 offset-md-3",
}))`
  ${(props) => {
    if (props.theme.skin === "abc") {
      return `
      .form-control {
        font-weight: 800;
      }
      @media (max-width: 767px) {
        button {
          max-width: 120px;
        }
      `;
    }
  }}
`;

export const RegisterStep2DatePickerWrapper = styled.div.attrs({
  className: "input-form col-md-6 mb-3",
})``;

export const RegisterBSNAndStepWrapper = styled.div.attrs((props) => ({
  className: props.stepone ? "mt-5 mb-4 text-center" : `my-5`,
}))`
  text-align: justify;
  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  } */
`;

export const RegisterNlStepWrapper = styled.div.attrs({
  className: "my-5 text-center col-md-10 offset-md-1",
})``;

export const RegisterText = styled.div.attrs({
  className: "my-5",
})`
  text-align: justify;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const FormRowLeft = styled.div.attrs((props) => ({
  className: `form-row justify-content-left ${props.mb}`,
}))``;

export const PnPWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

export const StyledParallax = styled.div.attrs((props) => ({
  className: props.zignsec && "zignsec",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 100%;
  text-align: center;

  &.zignsec {
    height: 15vh;
  }

  @media (max-width: 767px) {
    &.zignsec {
      height: 20vh;
    }
  }
`;

export const StyledHeadline = styled.div`
  font-size: 30px;
  line-height: 24px;

  p {
    color: #4d79ff;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 18px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledDepbox = styled.div`
  padding: 0 0 0 0;
  top: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;

  select,
  input {
    border: none;
  }

  i {
    position: relative;
    width: 5px;
    height: 4vh;
    background-color: #4f75ff;
    animation-name: blink;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    opacity: 1;
    transform: skew(-10deg);
  }

  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  select.form-control {
    background-image: none !important;
  }
  select {
    padding: 0;
    color: #fff;
    font-size: 4vh;
    line-height: initial;
    background-color: transparent;
    height: 100%;
    font-style: italic;

    :focus {
      background-color: transparent;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    padding: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  input[type="tel"] {
    font-style: italic;
    appearance: textfield;
    padding: inherit;
    font-size: inherit;
    font-weight: 400;
    -moz-appearance: textfield; /* Firefox */
    color: #fff;
    font-size: 4vh;
    line-height: initial;
    background-color: transparent;
    width: ${({ inputWidth }) => inputWidth};
    min-width: 90px;
    height: 100%;
    border-radius: 0;
    caret-color: transparent;
    text-align: right;
    padding: 10px;
    letter-spacing: 5px;
  }

  @media (max-width: 770px) {
    padding: 0;
  }
`;

export const PnPSelectAndInput = styled.select`
  /* font-family: "Catamaran"; */
  font-weight: 700;
  background: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  text-align: right;
  caret-color: #4f75ff;
  width: 100%;
  /* background-color: rgba(#fff, 0.1); */
  background-color: red;
  text-align: center;
  &:focus {
    outline: 0;
  }
  select {
    cursor: pointer;
    option {
      border: none !important;
      background-color: transparent !important;
    }
  }
`;

export const EditableOption = styled.option`
  color: #1b0072;
  font-weight: 700;
  font-size: 30px;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
`;

export const PnPImg = styled.img`
  width: 250px;
  padding-top: 5px;
  padding-left: 1rem;
  cursor: pointer;

  ${(props) => {
    if (props.headerBtn) {
      return `  
        position: absolute;
        top: 33px;
        right: 110px;
        width: 140px;
        z-index: 9999 !important;
      `;
    }
  }}

  @media (max-width: 700px) {
    width: ${({ headerBtn }) => (headerBtn ? "130px" : "150px")};
    right: ${({ headerBtn }) => headerBtn && "65px"};
    top: ${({ headerBtn }) => headerBtn && "30px"};
  }

  @media (max-width: 1000px) {
    width: ${({ headerBtn }) => !headerBtn && "170px"};
    height: ${({ headerBtn }) => !headerBtn && "100%"};
  }
  :hover {
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    transform: scale(1.1);
  }
`;

export const StyledContinue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  transition-delay: 0.75s;
  div {
    padding: 5rem 0 0 0;
    p {
      color: #fff;
    }
  }
`;

export const PnPContinueImg = styled.img`
  width: 220px;
  padding: 30px 0 0 15px;
  vertical-align: inherit;

  @media (max-width: 700px) {
    width: 124px;
    padding: 24px 0 0 0px;
  }

  @media (max-width: 1000px) {
    width: 160px;
    height: 100%;
    padding: 24px 0 0 0px;
  }
`;

export const TcppWrapper = styled.div`
  color: #4d79ff;
  font-weight: 500;
  padding-bottom: 10px;
  font-size: 12px;
  letter-spacing: -0.5px;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

export const PnPFramebox = styled.div`
  display: flex;
  flex-direction: column;

  // height: 70vh;
  height: 100vh;
  justify-content: center;
  // margin-top: -10vh;
  align-items: center;
  align-content: center;
`;

export const StyledIframe = styled(IFrame)`
  border: none;
  width: 100%;
  max-width: 1130px;
  min-height: 600px;
  background-color: white;
`;

export const PnPOtherPaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 39px;
  border-top: 1px solid silver;
  font-weight: 700;
  width: 100%;
  max-width: 1130px;
  background: var(--footerbg);
  min-height: 10vh;
  color: #4674c8;
`;

export const ZignsecStep = styled.div.attrs({
  className: "container",
})`
  height: 60vh;
  padding: 0 0 0 0;
`;

export const GDPRStepWrapper = styled.div.attrs((props) => ({
  className: `col-md-6 offset-md-3 mt-5 `,
}))`
  height: ${({ theme }) => (theme.skin === "rac" ? "" : "300px")};
  @media (max-width: 767px) {
    height: 100%;
  }
  .flex-table-cell {
    padding: 0px;
    .flex-table-cell-content {
      padding: 8px 0;
    }
  }
  ${(props) => {
    if (props.theme.skin === "abc") {
      return `
      margin-top: 1rem;
      @media (max-width: 767px) {
      button {
        max-width: 120px;
      }
  }
      `;
    }
  }}
`;

export const GDPRTableContent = styled.div.attrs((props) => ({
  className: props.mobile
    ? "flex-table-cell col-10"
    : `flex-table-cell ${
        props.theme.skin === "rac" ? "col-md-8" : "col-md-11"
      } col-sm-10`,
}))``;

export const RacGDPRTableContent = styled.div.attrs((props) => ({
  className: props.mobile
    ? "flex-table-cell col-10"
    : "flex-table-cell col-md-3 col-sm-10",
}))``;

export const GDPRTableContentLeft = styled.div.attrs((props) => ({
  className: `flex-table-cell-content ${
    props.theme.skin === "rac"
      ? props.spancenter
        ? "text-center"
        : "text-left"
      : "text-left"
  }`,
}))`
  a {
    color: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "var(--aclor)"};
    text-decoration: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "underline"};
  }
  backdrop-filter: ${({ skin }) => skin === "yet" && "blur(5px)"};
  -webkit-backdrop-filter: ${({ skin }) => skin === "yet" && "blur(5px)"};
  font-weight: ${({ skin }) => skin === "yet" && "bold"};
`;

export const GDPRStatusCell = styled.div.attrs((props) => ({
  className: props.mobile
    ? "flex-table-cell status col-2"
    : "flex-table-cell col-sm-2 col-md-1 status",
}))`
  -webkit-order: ${({ theme }) => theme.skin === "rac" && "3"};
  order: ${({ theme }) => theme.skin === "rac" && "3"};
`;

export const RegisterInputGroupWrapper = styled.div.attrs({
  className: "input-group col-md-12 mb-3 register-nl",
})``;

export const OffsetMd4 = styled.div.attrs({
  className: "col-md-4 offset-md-4",
})``;

export const ResisterStepUkAdress = styled.div.attrs((props) => ({
  className: props.lookupStep
    ? "form-group hidden"
    : "form-group col-md-12 mb-3 d-flex justify-content-between mt-3",
}))`
  @media (max-width: 767px) {
    button {
      max-width: 120px;
    }
  }
`;

export const LookupStepShow = styled.div.attrs((props) => ({
  className: props.lookupStep ? "form-row" : "hidden",
}))``;

export const LookupStepShowFlex = styled.div.attrs((props) => ({
  className: props.lookupStep
    ? "d-flex justify-content-between mt-3"
    : "hidden",
}))``;

export const StepidinFormGroup = styled.div.attrs({
  className: "form-group col-md-12 my-3 d-flex justify-content-center",
})``;

export const LogoIdinImg = styled.img`
  width: 40px;
  margin-left: 8px;
`;

export const IdinStepTwo = styled.div.attrs({
  className: "form-group col-md-6 offset-md-3 mb-3",
})``;

export const SpanStep = styled.span`
  display: inline-flex;
  flex-direction: row;

  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: ${({ theme }) =>
    theme.skin === "qbc"
      ? "#777474"
      : theme.skin !== "plc" && theme.skin !== "abc"
        ? "#fff"
        : "#0554b3"};
  background-image: ${({ theme }) =>
    theme.skin === "qbc"
      ? `url(${BubbleQBC})`
      : theme.skin !== "plc" && theme.skin !== "abc"
        ? `url(${BubblePUB})`
        : `url(${Bubble})`};
  height: 100px;
  width: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ theme }) =>
    theme.skin !== "plc" && theme.skin !== "abc" ? "40px" : "150px"};

  &.active {
    background-image: ${({ theme }) =>
      theme.skin !== "plc" && theme.skin !== "abc"
        ? theme.skin === "nbc"
          ? `url(${BubbleNBCActive})`
          : theme.skin === "bnk"
            ? `url(${BubbleBNKActive})`
            : theme.skin === "fun"
              ? `url(${BubbleFUNActive})`
              : theme.skin === "cac"
                ? `url(${BubbleCACActive})`
                : theme.skin === "rac"
                  ? `url(${BubbleRACActive})`
                  : theme.skin === "qbc"
                    ? `url(${BubbleQBCActive})`
                    : `url(${BubblePUBActive})`
        : `url(${BubbleFill})`};
    color: ${({ theme }) => theme.skin === "bnk" && "#0554b3"};
    background-position-x: ${({ theme }) => theme.skin === "qbc" && "-2px"};
    background-size: ${({ theme }) => theme.skin === "qbc" && "50px"};
  }

  &:nth-child(1) {
    background-image: ${({ theme }) =>
      theme.skin === "qbc"
        ? `url(${BubbleQBC})`
        : theme.skin !== "plc" && theme.skin !== "abc"
          ? `url(${BubblePUB})`
          : `url(${BubbleFirs})`};
    &.active {
      background-image: ${({ theme }) =>
        theme.skin !== "plc" && theme.skin !== "abc"
          ? theme.skin === "nbc"
            ? `url(${BubbleNBCActive})`
            : theme.skin === "bnk"
              ? `url(${BubbleBNKActive})`
              : theme.skin === "fun"
                ? `url(${BubbleFUNActive})`
                : theme.skin === "cac"
                  ? `url(${BubbleCACActive})`
                  : theme.skin === "rac"
                    ? `url(${BubbleRACActive})`
                    : theme.skin === "qbc"
                      ? `url(${BubbleQBCActive})`
                      : `url(${BubblePUBActive})`
          : `url(${BubbleFirsFill})`};
      color: ${({ theme }) =>
        theme.skin === "bnk"
          ? "#0554b3"
          : theme.skin === "qbc"
            ? "#FDAF1E"
            : "#fff"};
      background-position-x: ${({ theme }) => theme.skin === "qbc" && "-2px"};
      background-size: ${({ theme }) => theme.skin === "qbc" && "50px"};
    }
  }
  &:last-child {
    background-image: ${({ theme }) =>
      theme.skin === "abc" && `url(${BubbleLast})`};
    &.active {
      background-image: ${({ theme }) =>
        theme.skin === "abc" && `url(${BubbleLastFill})`};
      color: ${({ theme }) => (theme.skin === "qbc" ? "#FDAF1E" : "#fff")};
    }
  }
`;

export const RacRegisterWrapper = styled.div`
  a {
    color: var(--profileMenu);
    font-size: 1.2rem;
  }
`;
